// Checkout Component
export const CART_NOT_FOUND_MESSAGE = "Cart Not Found";
export const COMPLETE_ORDER_MESSAGE = "Complete Your Order";

// Routes Component
export const ITEM_ACCEPT_DETAILS_BUTTON_TEXT = "Accept Edits";
export const ITEM_ADD_TO_CART_BUTTON_TEXT = "Add To Cart";

// Complete Component
export const COMPLETE_TICKET_INFORMATION_SCHEDULE_MESSAGE = "scheduled";
export const COMPLETE_TICKET_INFORMATION_PREPARING_MESSAGE = "in the kitchen";
export const COMPLETE_PREP_TIME_MESSAGE = "Prep Time:";
export const COMPLETE_ESTIMATED_DELIVERY_AT = "Estimated Delivery:";
export const COMPLETE_ESTIMATED_PICKUP_AT = "Estimated Pickup:";
export const COMPLETE_PICKUP_FROM_TEXT = "Pickup From:";
export const COMPLETE_DELIVERING_FROM_TEXT = "Delivering From:";
export const COMPLETE_DINING_AT_TEXT = "Dining At:";
export const COMPLETE_BACK_TO_MENU_BUTTON_TEXT = "Back to Menu";
export const COMPLETE_SCHEDULE_DATE_HEADER_TEXT = "Schedule Date:";
export const COMPLETE_ORDER_INFORMATION_MESSAGE = "Your Order is {text}";
export const COMPLETE_ORDER_IN_KITCHEN_MESSAGE = "Your order is in the kitchen";
export const COMPLETE_CLOSE_BUTTON_TEXT = "Close";
export const COMPLETE_ADDITIONAL_MESSAGE = "";
export const COMPLETE_ORDER_SUBMITTED_MESSAGE =
  "YOUR CATERING REQUEST HAS BEEN SUBMITTED";

// Complete Type 2 Component
export const COMPLETE_ORDER_DATE_LABEL = "Order Date";
export const COMPLETE_ORDER_TIME_LABEL = "Order Time";
export const COMPLETE_CHECK_LABEL = "Check #";

// Complete Type 3 Component
export const COMPLETE_TYPE3_ORDER_INFORMATION_MESSAGE = "Your Order is ";
export const COMPLETE_TYPE3_TICKET_INFORMATION_SCHEDULE_MESSAGE = "\nscheduled";
export const COMPLETE_TYPE3_TICKET_INFORMATION_PREPARING_MESSAGE =
  "in \n the kitchen!";

// Complete Type 4 Component
export const COMPLETE_TYPE4_PICKUP_FROM_TEXT = "Pickup From ";
export const COMPLETE_TYPE4_DELIVERING_FROM_TEXT = "Delivering Address ";
export const COMPLETE_TYPE4_ESTIMATED_DELIVERING_FROM_TEXT =
  "Estimated Delivery Time:";
export const COMPLETE_TYPE4_ORDER_IN_THE_KITCHEN_MESSAGE =
  "Your Order Is In the Kitchen";
export const COMPLETE_TYPE4_ORDERED_TIME_HEADER = "Ordered on: {orderTime}";

// Confirm Component
export const CONFIRM_REMOVE_ITEM_BUTTON_TEXT = "Remove";
export const CONFIRM_GO_TO_CART_BUTTON_TEXT = "Go to Cart";
export const CONFIRM_REMOVE_ITEM_APPROVAL_MESSAGE =
  "\nAre you sure you want to remove \n{itemName} from your cart?";

// Discount Component
export const DISCOUNT_APPLY_BUTTON_TEXT = "Apply";
export const DISCOUNT_PROMO_CODE_INPUT_LABEL = "Promo Code";
export const DISCOUNT_PROMO_CODE_INPUT_PLACEHOLDER = "Enter Promo Code Here";

// Generate Link Component
export const GENERATE_LINK_ERROR_WHILE_CREATING_LINK_MESSAGE =
  "An Error Occured";
export const GENERATE_LINK_INPUT_LINK_LABEL = "Shareable Link";
export const GENERATE_LINK_SHARE_LINK_EMAIL_BUTTON_TEXT = "Email Link";
export const GENERATE_LINK_SHARE_LINK_TEXT_BUTTON_TEXT = "Text Link";
export const GENERATE_LINK_START_NEW_CART_BUTTON_TEXT = "Start New Cart";
export const GENERATE_COPY_LINK_SPAN_TEXT = "Copy";
export const GENERATE_COPIED_LINK_SPAN_TEXT = "Copied";

export default {
  CART_NOT_FOUND_MESSAGE,
  COMPLETE_BACK_TO_MENU_BUTTON_TEXT,
  COMPLETE_CHECK_LABEL,
  COMPLETE_CLOSE_BUTTON_TEXT,
  COMPLETE_DELIVERING_FROM_TEXT,
  COMPLETE_DINING_AT_TEXT,
  COMPLETE_ESTIMATED_DELIVERY_AT,
  COMPLETE_ESTIMATED_PICKUP_AT,
  COMPLETE_ORDER_DATE_LABEL,
  COMPLETE_ORDER_IN_KITCHEN_MESSAGE,
  COMPLETE_ORDER_INFORMATION_MESSAGE,
  COMPLETE_ORDER_MESSAGE,
  COMPLETE_ORDER_SUBMITTED_MESSAGE,
  COMPLETE_ORDER_TIME_LABEL,
  COMPLETE_PICKUP_FROM_TEXT,
  COMPLETE_PREP_TIME_MESSAGE,
  COMPLETE_SCHEDULE_DATE_HEADER_TEXT,
  COMPLETE_TICKET_INFORMATION_PREPARING_MESSAGE,
  COMPLETE_TICKET_INFORMATION_SCHEDULE_MESSAGE,
  COMPLETE_TYPE3_ORDER_INFORMATION_MESSAGE,
  COMPLETE_TYPE3_TICKET_INFORMATION_PREPARING_MESSAGE,
  COMPLETE_TYPE3_TICKET_INFORMATION_SCHEDULE_MESSAGE,
  COMPLETE_TYPE4_DELIVERING_FROM_TEXT,
  COMPLETE_TYPE4_ESTIMATED_DELIVERING_FROM_TEXT,
  COMPLETE_TYPE4_ORDER_IN_THE_KITCHEN_MESSAGE,
  COMPLETE_TYPE4_ORDERED_TIME_HEADER,
  COMPLETE_TYPE4_PICKUP_FROM_TEXT,
  CONFIRM_GO_TO_CART_BUTTON_TEXT,
  CONFIRM_REMOVE_ITEM_APPROVAL_MESSAGE,
  CONFIRM_REMOVE_ITEM_BUTTON_TEXT,
  DISCOUNT_APPLY_BUTTON_TEXT,
  DISCOUNT_PROMO_CODE_INPUT_LABEL,
  DISCOUNT_PROMO_CODE_INPUT_PLACEHOLDER,
  GENERATE_COPIED_LINK_SPAN_TEXT,
  GENERATE_COPY_LINK_SPAN_TEXT,
  GENERATE_LINK_ERROR_WHILE_CREATING_LINK_MESSAGE,
  GENERATE_LINK_INPUT_LINK_LABEL,
  GENERATE_LINK_SHARE_LINK_EMAIL_BUTTON_TEXT,
  GENERATE_LINK_SHARE_LINK_TEXT_BUTTON_TEXT,
  GENERATE_LINK_START_NEW_CART_BUTTON_TEXT,
  ITEM_ACCEPT_DETAILS_BUTTON_TEXT,
  ITEM_ADD_TO_CART_BUTTON_TEXT,
};
