// Payment Form Authorize.net Auhtorize Component
export const AUTHORIZE_NET_CARD_NUMBER_PLACEHOLDER = "•••• •••• •••• ••••";
export const AUTHORIZE_NET_CARD_CVV_NUMBER_PLACEHOLDER = "123";
export const AUTHORIZE_NET_CARD_EXPIRATION_MONTH_LABEL = "Expiration Month";
export const AUTHORIZE_NET_CARD_EXPIRATION_MONTH_PLACEHOLDER = "MM";
export const AUTHORIZE_NET_CARD_EXPIRATION_YEAR_LABEL = "Expiration Year";
export const AUTHORIZE_NET_CARD_EXPIRATION_YEAR_PLACEHOLDER = "YYYY";
export const AUTHORIZE_NET_CARD_ZIPCODE_LABEL = "Postal";
export const AUTHORIZE_NET_CARD_ZIPCODE_PLACEHOLDER = "12345";
export const AUTHORIZE_NET_CARD_STREET_ADDRESS_LABEL = "Billing Street Address";

// Payment Form Authorize.net Auhtorize2 Component
export const AUTHORIZE_NET_MISSING_LOCATION_MESSAGE =
  "You must select a location from the home screen in order to add a card";

// Payment Form Seamless Component
export const SEAMLESS_ADD_EXPIRATION_DATE_MESSAGE =
  "Please enter an expiration date";
export const SEAMLESS_UNKNOWN_ERROR_MESSAGE = "Unknown Error";
export const SEAMLESS_SOME_FIELDS_EMPTY_MESSAGE =
  "All or Some fields empty. Please complete all the fields.";
export const SEAMLESS_SYSTEM_ERROR_MESSAGE =
  "System is temporarily unavailable, please try later";
export const SEAMLESS_SYSTEM_DEFAULT_ERROR_MESSAGE =
  "System is temporarily unavailable, please try later";
export const SEAMLESS_ACCOUNT_NUMBER_LABEL = "Account Number";
export const SEAMLESS_CARD_EXPIRATION_DATE_LABEL = "Expiration Date";
export const SEAMLESS_CARD_CVV_NUMBER_LABEL = "CVV";
export const SEAMLESS_CARD_NUMBER_LABEL = "Card Number";
export const SEAMLESS_CARD_ZIPCODE_LABEL = "Zip Code";

// Payment Form Square Component
export const SQUARE_CARD_NUMBER_PLACEHOLDER = "•••• •••• •••• ••••";
export const SQUARE_CARD_CVV_NUMBER_PLACEHOLDER = "123";
export const SQUARE_CARD_EXPIRATION_DATE_PLACEHOLDER = "MM/YY";
export const SQUARE_CARD_ZIPCODE_PLACEHOLDER = "12345";
export const SQUARE_CARD_NUMBER_LABEL = "Card Number";
export const SQUARE_CARD_EXPIRATION_DATE_LABEL = "Expiration";
export const SQUARE_CARD_CVV_NUMBER_LABEL = "CVV";
export const SQUARE_CARD_ZIPCODE_LABEL = "Postal";
export const SQUARE_CARD_INFORMATION_ERROR_MESSAGE =
  "Please fix card information errors before continuing.";
export const BUTTON_TEXT_SAVING = "Saving";
export const SQUARE_PAYMENT_FORM_UNSUPPORTED_BROWSER =
  "Oops Your browser doesn't support this payment flow";

// Payment Form Stripe Component
export const PAYMENT_FORM_SAVE_BUTTON_TEXT = "Save";

export default {
  AUTHORIZE_NET_CARD_CVV_NUMBER_PLACEHOLDER,
  AUTHORIZE_NET_CARD_EXPIRATION_MONTH_LABEL,
  AUTHORIZE_NET_CARD_EXPIRATION_MONTH_PLACEHOLDER,
  AUTHORIZE_NET_CARD_EXPIRATION_YEAR_LABEL,
  AUTHORIZE_NET_CARD_EXPIRATION_YEAR_PLACEHOLDER,
  AUTHORIZE_NET_CARD_NUMBER_PLACEHOLDER,
  AUTHORIZE_NET_CARD_STREET_ADDRESS_LABEL,
  AUTHORIZE_NET_CARD_ZIPCODE_LABEL,
  AUTHORIZE_NET_CARD_ZIPCODE_PLACEHOLDER,
  AUTHORIZE_NET_MISSING_LOCATION_MESSAGE,
  BUTTON_TEXT_SAVING,
  PAYMENT_FORM_SAVE_BUTTON_TEXT,
  SEAMLESS_ACCOUNT_NUMBER_LABEL,
  SEAMLESS_ADD_EXPIRATION_DATE_MESSAGE,
  SEAMLESS_CARD_CVV_NUMBER_LABEL,
  SEAMLESS_CARD_EXPIRATION_DATE_LABEL,
  SEAMLESS_CARD_NUMBER_LABEL,
  SEAMLESS_CARD_ZIPCODE_LABEL,
  SEAMLESS_SOME_FIELDS_EMPTY_MESSAGE,
  SEAMLESS_SYSTEM_DEFAULT_ERROR_MESSAGE,
  SEAMLESS_SYSTEM_ERROR_MESSAGE,

  SEAMLESS_UNKNOWN_ERROR_MESSAGE,
  SQUARE_CARD_CVV_NUMBER_LABEL,
  SQUARE_CARD_CVV_NUMBER_PLACEHOLDER,
  SQUARE_CARD_EXPIRATION_DATE_LABEL,
  SQUARE_CARD_EXPIRATION_DATE_PLACEHOLDER,
  SQUARE_CARD_INFORMATION_ERROR_MESSAGE,
  SQUARE_CARD_NUMBER_LABEL,
  SQUARE_CARD_NUMBER_PLACEHOLDER,
  SQUARE_CARD_ZIPCODE_LABEL,
  SQUARE_CARD_ZIPCODE_PLACEHOLDER,
  SQUARE_PAYMENT_FORM_UNSUPPORTED_BROWSER,
};
