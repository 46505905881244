import * as React from "react";
import csx from "classnames";
import css from "../common.module.scss";

const googleGsi = "https://accounts.google.com/gsi/client";

const loadScript = () =>
  new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${googleGsi}"]`)) {
      resolve();
      return;
    }
    const script = document.createElement("script");
    script.src = googleGsi;
    script.onload = () => resolve();
    script.onerror = (err) => reject(err);
    document.body.appendChild(script);
  });

const GoogleButton = ({ clientId, socialLogin }) => {
  const googleButton = React.useRef(null);

  function handleCredentialResponse(response) {
    socialLogin({ tokenId: response.credential }, "google");
  }

  React.useEffect(() => {
    loadScript()
      .then(() => {
        /* global google */
        google.accounts.id.initialize({
          client_id: clientId,
          callback: handleCredentialResponse,
        });
        google.accounts.id.renderButton(googleButton.current, {
          theme: "outline",
          size: "large",
          width: googleButton.current.clientWidth,
          shape: 'rectangular',
          type: 'standard'
        });
      })
      .catch(console.error);

    return () => {
      const scriptTag = document.querySelector(`script[src="${googleGsi}"]`);
      if (scriptTag) document.body.removeChild(scriptTag);
    };
  }, []);

  

  return <div className={csx(css.btn, css.google)} ref={googleButton} />;
}


export default GoogleButton;