export const ORDER_DETAILS_NOTES_PLACEHOLDER_PICKUP =
  "Please let us know if you have any additional notes for the team";
export const ORDER_DETAILS_NOTES_PLACEHOLDER_DELIVERY =
  "Please let us know if you have any additional notes for the team";
export const CONTACT_PHONE =
  "Contact Phone";

export default {
  ORDER_DETAILS_NOTES_PLACEHOLDER_DELIVERY,
  ORDER_DETAILS_NOTES_PLACEHOLDER_PICKUP,
  CONTACT_PHONE
};
