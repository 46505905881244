// Validate Login Component
export const INPUT_LABEL = "Email";
export const INPUT_PLACEHOLDER = "Enter Email Here";
export const BUTTON_TEXT = "Next";

export default {
  BUTTON_TEXT,
  INPUT_LABEL,
  INPUT_PLACEHOLDER,
};
