// Delivery Form Component
const FORM_ADDRESS_UNAVAILABLE_MESSAGE =
  "Unable to deliver to the address you've provided.";
const FORM_LIVE_SEARCH_MESSAGE = "Searching...";
const FORM_SEARCH_FORM_ADDRESS_PLACEHOLDER = "Enter Your Address";
const FORM_SEARCH_FORM_ADDRESS_LABEL = "Where's your food going?";
const APARTMENT_SUIT_INPUT_LABEL = "Apt/Suite";
const APARTMENT_SUIT_INPUT_PLACEHOLDER = "Optional";
const DELIVERY_LOCATION_SEARCH_BUTTON_TEXT = "Search";
const EDIT_DELIVERY_ADDRESS_BUTTON_TEXT = "Edit";

export {
  FORM_ADDRESS_UNAVAILABLE_MESSAGE,
  FORM_LIVE_SEARCH_MESSAGE,
  FORM_SEARCH_FORM_ADDRESS_PLACEHOLDER,
  FORM_SEARCH_FORM_ADDRESS_LABEL,
  APARTMENT_SUIT_INPUT_LABEL,
  APARTMENT_SUIT_INPUT_PLACEHOLDER,
  DELIVERY_LOCATION_SEARCH_BUTTON_TEXT,
  EDIT_DELIVERY_ADDRESS_BUTTON_TEXT,
};

export default {
  APARTMENT_SUIT_INPUT_LABEL,
  APARTMENT_SUIT_INPUT_PLACEHOLDER,
  DELIVERY_LOCATION_SEARCH_BUTTON_TEXT,
  EDIT_DELIVERY_ADDRESS_BUTTON_TEXT,
  FORM_ADDRESS_UNAVAILABLE_MESSAGE,
  FORM_LIVE_SEARCH_MESSAGE,
  FORM_SEARCH_FORM_ADDRESS_LABEL,
  FORM_SEARCH_FORM_ADDRESS_PLACEHOLDER,
};
